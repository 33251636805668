import React, { useEffect, useState } from "react";
import TopNav from "./top_nav";
import DynamicBanner from "./dynamic_banner";
import Footer from "./footer";
import encryptStorage from "../../utils/Encryptstorage";
import { Row, Col, Button } from "reactstrap";
import { useHistory } from "react-router";
import axios from "axios";
import requests from "../../utils/Requests";
import Swal from "sweetalert2";

const MofConfirmation = () => {
  const history = useHistory();

  const [agency, setagency] = useState(0);
  const [agencyuser, setagencyuser] = useState(0);
  const [userId, setuserId] = useState(0);
  const [exitData, setexitData] = useState(null);
  const [isLoading, setisLoading] = useState(null);
  let login = sessionStorage.getItem("isLoggedIn");
  useEffect(() => {
    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      if (urlObject.b2b == true) {
        setagency(urlObject.agency.agencyId);
      } else if (urlObject.b2bUser == true) {
        setagencyuser(urlObject.agencyUser.agencyUserId);
      } else {
        setuserId(urlObject.customer.customerId);
      }
    }
    window.scrollTo(0, 0);
    const preObj = encryptStorage.getItem("mofForBooking"); // obj

    let existObj = JSON.parse(preObj);
    setexitData(existObj);

    if (!existObj) {
      history.goBack();
    }
    console.log("1212121212121212", existObj);
  }, []);

  useEffect(() => {
    if (exitData) {
      encryptStorage.setItem("mofForBooking", JSON.stringify(exitData));
    }
  }, [exitData]);

  useEffect(() => {
    // Assuming other initial data is fetched here
    setexitData((prevData) => ({
      ...prevData,
      bookB2cId: userId, // Set bookB2cId
      agencyId: agency, // Set agencyId
      agencyUserId: agencyuser, // Set agencyUserId
    }));
  }, [userId, agency, agencyuser]); // Add dependencies

  const emptyShopCartData = async (shopCartId) => {
    await axios
      .post(requests.emptyShopCart, { shopCartId: shopCartId })
      .then((res) => {
        console.log(res.data);
        if (res.data.errorCode === 200) {
          encryptStorage.removeItem("mofForBooking");

          Swal.fire("Sucess", "Your cart is empty", "error");
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeShopCartData = async (
    shopCartId,
    removeShopCartItemId,
    adultOrChild
  ) => {
    const postObject = {
      shopCartId: shopCartId,
      removeShopCartItemId: removeShopCartItemId,
    };
    await axios
      .post(requests.removeShopCart, postObject)
      .then((res) => {
        console.log(res.data);

        if (res.data.errorCode === 200) {
          setexitData((prevData) => {
            const updatedData = {
              ...prevData,
              nofAdult:
                adultOrChild === "adult"
                  ? res.data.editQuantity
                  : exitData.nofAdult,
              nofChild:
                adultOrChild === "child"
                  ? res.data.editQuantity
                  : exitData.nofChild,
              totalAmount: res.data.totalAmount,
            };
            // Store updated data in encryptStorage
            encryptStorage.setItem(
              "mofForBooking",
              JSON.stringify(updatedData)
            );

            if (updatedData.nofAdult == 0 && updatedData.nofChild == 0) {
              encryptStorage.removeItem("mofForBooking");

              Swal.fire("Sucess", "Your cart is empty", "error");
              history.goBack();
            }

            return updatedData;
          });
        } else {
          Swal.fire("Error", res.data.errorMessage, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addorRemoveitem = async (
    shopCartId,
    editShopCartItemId,
    editQuantity,
    adultOrChild
  ) => {
    setisLoading(adultOrChild);
    console.log("abxisabdixisx");
    const postObject = {
      shopCartId: shopCartId,
      editShopCartItemId: editShopCartItemId,
      editQuantity: editQuantity,
    };
    await axios
      .post(requests.editShopCart, postObject)
      .then((res) => {
        console.log(res.data);

        if (res.data.errorCode === 200) {
          if (adultOrChild === "adult") {
          }

          setexitData((prevData) => {
            const updatedData = {
              ...prevData,
              nofAdult:
                adultOrChild === "adult"
                  ? Number(exitData.nofAdult) + Number(res.data.editQuantity)
                  : exitData.nofAdult,
              nofChild:
                adultOrChild === "child"
                  ? Number(exitData.nofChild) + Number(res.data.editQuantity)
                  : exitData.nofChild,
              totalAmount: res.data.totalAmount,
            };
            // Store updated data in encryptStorage

            encryptStorage.setItem(
              "mofForBooking",
              JSON.stringify(updatedData)
            );

            if (updatedData.nofAdult == 0 && updatedData.nofChild == 0) {
              encryptStorage.removeItem("mofForBooking");

              Swal.fire("Sucess", "Your cart is empty", "error");
              history.goBack();
            }
            setisLoading(null);
            return updatedData;
          });
        } else {
          setisLoading(null);
          Swal.fire("Error", res.data.errorMessage, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitToApi = async () => {
    Swal.fire({
      icon: "info",
      title: "Wait",
      text: "Please Wait Your Ticket Is Processing",
    });

    console.log(
      `Post Object for final call ${JSON.stringify(exitData, null, 2)}`
    );
    await axios
      .post(requests.confirmTicket, exitData)
      .then((res) => {
        if (res.data.errorCode == "200") {
          console.log(res.data);
          let filename = res.data.pdfPath;
          const fileURL =
            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
            "https://b2b.parmartours.com/filestorage/" + res.data.pdfPath;
          Swal.fire({
            title: "Booking Confirmed",
            text: "Thank You For Your Booking, press OK to download your e-Ticket",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok",
            denyButtonText: `Don't save`,
          }).then((result) => {
            if (result.isConfirmed) {
              download_file(fileURL, filename);

              //window.location.replace(fileURL)
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res.data.errorCode,
            text: res.data.errorMessage,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
    history.goBack();
  }
  return (
    <div>
      <TopNav />
      <DynamicBanner />
      <div
        className="container"
        style={{
          marginBottom: "20px",
        }}
      >
        <Row>
          <Col xs={2}></Col>
          <Col xs={8}>
            <div className="container rounded bg-white">
              <div className="d-flex justify-content-end">
                <a
                  class="cancel com-color"
                  onClick={history.goBack}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Cancel and return to website
                </a>
              </div>
              <div className="d-flex justify-content-center">
                <h4 className="single-page-small-title">
                  Your Booking Details
                </h4>
              </div>
              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    <h6>Park Name </h6>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    {exitData?.parkName}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    <h6>Booking Customer Name</h6>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    {exitData?.firstName} {exitData?.lastName}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    <h6>Your Email</h6>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    {exitData?.eMail}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    <h6>Contact</h6>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    {exitData?.contactNumber}
                  </div>
                </Col>
              </Row>
              {/* <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">{ctkttype}</div>
              </Col>
            </Row> */}

              {exitData?.nofAdult != 0 && (
                <Row>
                  <Col xs={6}>
                    <div className="d-flex justify-content-start">
                      <h6>Number Of Adult</h6>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex justify-content-start">
                      {exitData?.nofAdult}{" "}
                      {isLoading === "adult" ? (
                        <div id="loading"></div>
                      ) : (
                        <Button
                          onClick={() =>
                            addorRemoveitem(
                              exitData?.shopCartId,
                              exitData?.adultShopCartItemId,
                              1,
                              "adult"
                            )
                          }
                        >
                          +
                        </Button>
                      )}
                      <div
                        style={{
                          marginLeft: "20px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          removeShopCartData(
                            exitData?.shopCartId,
                            exitData?.adultShopCartItemId,
                            "adult"
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {exitData?.nofChild != 0 && (
                <Row>
                  <Col xs={6}>
                    <div className="d-flex justify-content-start">
                      <h6>Number Of Child</h6>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex justify-content-start">
                      {exitData?.nofChild}
                      {isLoading === "child" ? (
                        <div id="loading"></div>
                      ) : (
                        <Button
                          onClick={() =>
                            addorRemoveitem(
                              exitData?.shopCartId,
                              exitData?.childShopCartItemId,
                              1,
                              "child"
                            )
                          }
                        >
                          +
                        </Button>
                      )}

                      <div
                        style={{
                          marginLeft: "20px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          removeShopCartData(
                            exitData?.shopCartId,
                            exitData?.childShopCartItemId,
                            "child"
                          )
                        }
                      >
                        Remove
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    <h6>Total Amount</h6>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className="d-flex justify-content-start">
                    {exitData?.totalAmount}
                  </div>
                </Col>
              </Row>
              <br />

              <Row>
                <Col xs={6}></Col>

                <Col xs={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Button
                      color="primary"
                      className="btn btn-yellow"
                      onClick={() => submitToApi()}
                    >
                      Book Now
                    </Button>
                    <div className="d-flex justify-content-start">
                      <div class="buttons">
                        <div
                          style={{
                            marginLeft: "20px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            emptyShopCartData(exitData?.shopCartId)
                          }
                        >
                          Remove All
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default MofConfirmation;
