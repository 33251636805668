import { reduxForm, Field, Form } from "redux-form";
import { useEffect, useState } from "react";
import RenderField from "../form-components/RenderField";
import axios from "axios";
import requests from "../utils/Requests";
import { Alert } from "reactstrap";
import encryptStorage from "../utils/Encryptstorage";
import { useHistory } from "react-router";
const OtpVerificationForm = (props) => {
  let history = useHistory();
  let propscode = props.servercode;
  useEffect(() => {
    console.log(sessionStorage.getItem("user"));
    // props.initialize({ b2c:"true"})
    // console.log("propscode", propscode);
    if (propscode == 101) {
      callotp();
    } else {
    }
    //  callotp();
  }, []);
  const { handleSubmit, pristine, reset, submitting } = props;

  const [serverRes, setserverRes] = useState();

  const [alertTime, setalertTime] = useState(0);
  const callotp = () => {
    let userName = sessionStorage.getItem("userEmail");
    axios
      .post(requests.setotpforexistinguser, {
        userName: userName,
        platformId: 1,
      })
      .then((res) => {
        let errcode = res.data.errorCode;
        if (errcode == 0) {
          //window.location.href = "/user-otp-veryfy-page";
        } else {
          setserverRes("Invalid OTP");
          setalertTime(1);
          setTimeout(function () {
            //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
            // Alert.alert("Alert Shows After 5 Seconds of Delay.")
            setalertTime(0);
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitotpVeryfy = (values, dispatch) => {
    let userEmail = sessionStorage.getItem("eMail");
    let b2b = false,
      b2bUser = false,
      b2c = false;
    let userType = sessionStorage.getItem("user");
    if (userType == "b2b") {
      b2b = true;
    } else if (userType == "b2bUser") {
      b2bUser = true;
    } else if (userType == "b2c") {
      b2c = true;
    }

    const userData = {
      b2c: b2c,
      b2b: b2b,
      b2bUser: b2bUser,
      userName: userEmail,
      otp: values.otp,
    };
    console.log("lanajnax", userData);
    axios

      .post(requests.verifyotpuser, userData)
      .then((res) => {
        // console.log(res.data);

        if (res.data.errorCode == 100) {
          // sessionStorage.removeItem("user");
          //success
          // setalertTime(2);

          //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
          // Alert.alert("Alert Shows After 5 Seconds of Delay.")
          setalertTime(0);
          if (propscode == 120) {
            props.otpVerify(10);
          } else {
            props.otpVerify(1);
          }
          // props.otpVerify(1);
        } else if (res.data.errorCode == 102) {
          //invalid otp
          setserverRes("Invalid OTP");
          setalertTime(1);
          setTimeout(function () {
            //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
            // Alert.alert("Alert Shows After 5 Seconds of Delay.")
            setalertTime(0);
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {alertTime == 1 ? (
        <>
          <Alert color="danger">{serverRes}</Alert>
        </>
      ) : alertTime == 2 ? (
        <Alert color="primary">
          OTP Verified Please Login With Your User Email and Password
        </Alert>
      ) : null}
      <Form onSubmit={handleSubmit(handleSubmitotpVeryfy.bind(this))}>
        <Field
          name="otp"
          type="text"
          icon=""
          component={RenderField.RenderTextField}
        />
        <br />

        <button type="submit" class="btn btn-yellow" disabled={submitting}>
          Verify{" "}
        </button>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "OtpVerificationForm",
})(OtpVerificationForm);
